import React, { FC } from 'react'

import { useFootnoteFooterContext } from '@/contexts'
import { COLORS, Footnote, Layout } from 'ethos-design-system'

import { useSingleCTAPriceComparison } from '@/hooks/features/useSingleCTAPriceComparison'

import Markdown, { MARKDOWN_NODE_RESTRICTION_SETS } from '../../Markdown'
import styles from './FootnoteFooter.module.scss'

interface IFootnoteFooter {
  footNoteColor?: any
}

const FootnoteFooter: FC<IFootnoteFooter> = ({
  footNoteColor = COLORS.WHITE,
}) => {
  const { displayFootnoteFooter, bodyText } = useFootnoteFooterContext()
  const { disclosure: priceComparisonDisclosure } =
    useSingleCTAPriceComparison()

  if (!displayFootnoteFooter) return null

  let experimentBodyText = bodyText || ''
  if (priceComparisonDisclosure) {
    if (bodyText.length > 0) experimentBodyText += '\n\n'
    experimentBodyText += priceComparisonDisclosure
  }

  if (experimentBodyText.length === 0) return null

  return (
    <Layout.HorizontallyPaddedContainer>
      <div id="footnote" className={styles.root}>
        <Footnote.Regular400 color={footNoteColor}>
          <Markdown
            input={experimentBodyText}
            allowedMarkdownTypes={
              MARKDOWN_NODE_RESTRICTION_SETS.BASIC_TEXT_WITH_LINKS
            }
          />
        </Footnote.Regular400>
      </div>
    </Layout.HorizontallyPaddedContainer>
  )
}

export default FootnoteFooter
